<template>
  <form class="reset-password-form" @submit.prevent="onSubmit">
    <ion-password-input
      id="newPass"
      v-model="newPassword"
      :color="validatorColor(pwOk)"
      class="c-input mt-1"
      placeholder="New Password (8+ characters)"
      autocapitalize="off"
      type="password"
      @ionInput="pwOk = true"
    />
    <ion-password-input
      id="repeatPass"
      v-model="repeatPassword"
      :color="validatorColor(pwOk)"
      class="c-input mt-1"
      placeholder="Repeat Password"
      autocapitalize="off"
      type="password"
      @ionInput="pwOk = true"
    />

    <div v-if="!pwOk" class="error-msg mb-2">
      <span v-if="newPassword !== repeatPassword">Entered passwords do not match</span>
      <span v-else>Password must be 8+ characters long</span>
    </div>
    <ion-custom-button
      :loading="loading"
      :disabled="!newPassword || !repeatPassword || loading"
      class="reset-button"
      type="submit"
    >
      Reset Password
    </ion-custom-button>
  </form>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-property-decorator';
import { toast } from '@/shared/native';
import IonCustomButton from '@/shared/components/ion-custom-button.vue';
import IonPasswordInput from '@/shared/components/ion-password-input.vue';
import { passwordIsValid } from '@/shared/utils/auth';
import { resetPasswordConfirm } from '@/shared/services/auth';

@Options({
  name: 'ResetPasswordForm',
  components: {
    IonCustomButton,
    IonPasswordInput,
  },
})
export default class ForgetPasswordForm extends Vue {
  public requestingEmail = false;
  public newPassword = '';
  public repeatPassword = '';
  public pwOk = true;
  public loading = false;

  public async handleError(e: any) {
    this.loading = false;
    let message = 'Something went wrong when resetting the password. Please try again later';
    try {
      if (e && e.response) {
        if (e.response.data && e.response.data.password) {
          message = e.response.data.password[0];
        }
      }
    } catch {}
    await toast.show(message, 'nonative', 'danger');
  }

  public async onSubmit() {
    this.pwOk = passwordIsValid(this.newPassword) && this.newPassword === this.repeatPassword;

    if (!this.pwOk) return;

    try {
      const router = useRouter();
      this.loading = true;

      const token = encodeURIComponent(router.currentRoute.value.query.token);

      await resetPasswordConfirm({ password: this.newPassword, token });
      await toast.show(
        'Password has been reset successfully. You can now log in using your new password.',
        'nonative',
        'success'
      );

      this.$nextTick(() => router.push('login'));
    } catch (e) {
      return this.handleError(e);
    } finally {
      this.loading = false;
    }
  }

  public validatorColor(value: boolean) {
    return !value ? 'danger' : '';
  }
}
</script>

<style lang="sass" scoped>
.c-input
  margin-bottom: 16px
.error-msg
  color: var(--ion-color-danger)
</style>
