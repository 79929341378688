<template>
  <ion-page class="page white">
    <h1 class="reset-pass-title">Reset Password</h1>
    <p>Please enter a new password for your account.</p>
    <reset-password-form class="reset-password-form"/>
  </ion-page>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-property-decorator';
import ResetPasswordForm from './components/ResetPasswordForm.vue';

@Options({
  name: 'ResetPasswordPage',
  components: {
    ResetPasswordForm,
  },
})
export default class ResetPasswordPage extends Vue {}
</script>

<style scoped lang="sass">
.page
  text-align: center
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  padding: 20px
  *
    font-size: 0.9rem
.logo
  height: 50px
.reset-password-form
  text-align: start
  width: 100%
  margin-top: 16px
  .reset-password-form
    margin-top: 16px
    
.reset-pass-title
  font-size: 26px
</style>
